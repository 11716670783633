import type { TagWithStatistics } from "~/business-areas/tag/tag.model";

type TagSearchFilters = {
  depth?: number | false;
  ancestors_of?: string;
  children_of?: MaybeRef<string[]>;
  used?: boolean;
  sort_field?: keyof TagWithStatistics;
  sort_order?: "ASC" | "DESC";
};

export const useTagList = (
  filters?: TagSearchFilters,
  options?: { server?: boolean; lazy?: boolean; dedupe?: "cancel" | "defer" },
) => {
  return useFetch<TagWithStatistics[]>("/api/tags", {
    query: filters,
    default: () => [],
    ...options,
  });
};
